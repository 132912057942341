import  { useEffect, useRef, useState } from 'react';
import { SliderData } from './sliderData';
import RightArrow from './icons/right_arrow.svg';
import LeftArrow from './icons/left_arrow.svg';
import './style.scss'
import { gsap } from 'gsap';

function Slider() {
  const imgSlide = useRef<any>();
  const [imageIndex, setImageIndex] = useState(0);


  const next = () => {
    setImageIndex(state => (state += 1));
    if (imageIndex === SliderData.length - 1) setImageIndex(0);
  };


  const prev = () => {
    setImageIndex(state => (state -= 1));
    if (imageIndex === 0) setImageIndex(SliderData.length - 1);
  };


  useEffect(() => {
    gsap.fromTo(
      '.sliderShow',
      { opacity: 0.5 },
      { duration: 0.6, opacity: 1, ease: 'ease-out' }
    );
    gsap.fromTo(
      '.leftArrow_slider',
      { scale: 0.8 },
      { duration: 0.6, scale: 1 }
    );
    gsap.fromTo(
      '.rightArrow_slider',
      { scale: 0.8 },
      { duration: 0.6, scale: 1 }
    );
  }, [imageIndex]);



  return (

    <div className="sliderContainer">
      <img
        onClick={next}
        className="rightArrow_slider"
        src={RightArrow}
        alt="Right Arrow"
      />
      <img
        onClick={prev}
        className="leftArrow_slider"
        src={LeftArrow}
        alt="Left Arrow"
      />

      <div className="sliderShow" ref={imgSlide}>
        <img
          className="imgOnSlider"
          src={SliderData[imageIndex].image}
          alt="sliderimages"
        />

        <div className="dotContainer">
          {SliderData.map((dot: any, index: number): any => (
            <div
              key={dot.image}
              className={index === imageIndex ? 'dotClicked' : 'dotNotClicked'}
            />
          ))}
        </div>
      </div>
    </div>

  );
}

export default Slider;
