import ImgTwo from './photos/ImgTwo.jpg';
import ImgThree from './photos/ImgThree.jpg';
import ImgOne from './photos/ImgOne.jpg';
export const SliderData: any = [
  {
    title: 'image one',
    image: ImgOne,
  },
  {
    title: 'image two',
    image: ImgTwo,
  },
  {
    title: 'image three',
    image: ImgThree,
  },
];
